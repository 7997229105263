import React, { FunctionComponent, ReactElement, useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAtom } from 'jotai';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// import { fetch_all, fetch_one } from '../../lib/server_helper';
import { fetch_all, fetch_one } from "../../lib/v31lib";
// import { currentPracticeAtom } from '../../lib/practice';
import { loginAtom } from '../../lib/auth';

import ImgOnline from '../../images/public_find/lang_online.png';
import ImgPhysical from '../../images/public_find/lang_fysiek.png';
import ImgAny from '../../images/public_find/lang_any.png';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import EventIcon from '@mui/icons-material/Event';
import GroupIcon from '@mui/icons-material/Group';
import LaunchIcon from '@mui/icons-material/Launch';
import VideoCallIcon from '@mui/icons-material/VideoCall';

import {
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  ImageListItem,
  LinearProgress,
  MenuItem,
  Paper
} from '@mui/material';

import {
  Button,
  Fab,
  IconButton,
  ImageList,
  MultiSelect,
  Page,
  TextInput,
  Typography
} from '../../components/v2/styled';
import { PracticeLogoAvatar } from '../../components/v2/PracticeLogoAvatar';
import { CardReview } from '../../components/v2/cards/Review';
import { CardCoach } from '../../components/v2/cards/Coach';
import { CardLocation } from '../../components/v2/cards/Location';

import { BE, NL } from 'country-flag-icons/react/3x2';

import {APIProvider, AdvancedMarker, InfoWindow, Map, Pin, useMap} from '@vis.gl/react-google-maps';
import {MarkerClusterer} from '@googlemaps/markerclusterer';
import type {Marker} from '@googlemaps/markerclusterer';

import { CoachSkill } from '../../models/CoachSkill';
import { Location } from '../../models/Location';
import { Practice } from '../../models/Practice';
import { QuestionnaireResult } from '../../models/QuestionnaireResult';

import HelpersLanguage from '../../actions/helpers/language';

// const { DateTime } = require("luxon");

type Poi = { key: string, location: google.maps.LatLngLiteral, o: any }
type Props = {};

export const SharedFind: FunctionComponent<Props> = ({}) => {
  const theme = useTheme();
  const [login, setLogin] = useAtom(loginAtom);
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [locations, setLocations] = useState<Location[]>([]);
  const [practices, setPractices] = useState<Practice[]>([]);
  const [practicesOnline, setPracticesOnline] = useState<Practice[]>([]);
  const [practicesBoth, setPracticesBoth] = useState<Practice[]>([]);
  const [locs, setLocs] = useState<Poi[]>([]);
  // const matches = useMediaQuery('(min-width:600px)');
  const [selectedPractice, setSelectedPractice] = useState<Practice>();
  const [search, setSearch] = useState<string>('');
  const [skills, setSkills] = useState<number[] | string[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<Location>();
  const [progress, setProgress] = useState(0);
  const [mode, setMode] = useState<'none'|'both'|'phys'|'online'>('none');
  const [filterCountries, setFilterCountries] = useState<number[]>([1, 2]);

  const {
    data: coachSkills,
    isLoading: coachSkillsLoading,
    isError: coachSkillsError,
    isSuccess: coachSkillsSuccess,
    refetch: coachSkillsRefetch,
  } = useQuery({
    queryKey: ["coach_skills"],
    queryFn: () =>
      fetch_all<CoachSkill>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'coach_skill',
          fields: ['id', 'name'],
          page: 0,
          per_page: 1000,
          order: "name ASC"
        },
        undefined
      ),
    // select: (d) => {
    //   return d.custom_result.data;
    // },
    // enabled: !!login,
  });

  const {
    data: questionnaireResults,
    isLoading: questionnaireResultsLoading,
    isError: questionnaireResultsError,
    isSuccess: questionnaireResultsSuccess,
    refetch: questionnaireResultsRefetch,
  } = useQuery({
    queryKey: ["onboarding", "questionnaire_results", selectedPractice?.id],
    queryFn: () =>
      fetch_all<QuestionnaireResult>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'questionnaire_result',
          fields: [
            'id', 'extra_data', 'privacy_user', 'updated_at'
          ],
          per_page: 3,
          page: 0,
          order: "updated_at DESC",
          filter: {
            search: '',
            advanced: {
              practice_id: selectedPractice?.id,
              is_frontpage: 1,
              is_review: 1,
              is_completed: 1
            }
          },
        }
      ),
    enabled: !!selectedPractice && !!selectedPractice.settings?.show_reviews_on_frontend,
  });








  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{custom_result: {locations: Location[], practices: Practice[], online: Practice[], both: Practice[]}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      setProgress(20);
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setLocations(data.custom_result.locations);
      setPractices(data.custom_result.practices);
      setPracticesBoth(data.custom_result.both);
      setPracticesOnline(data.custom_result.online);
      setProgress(-1);
    },
  });
  const mutationSubscribe = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      navigate("/settings", {state: {tab: 1, refresh: 'hard'}});
    },
  });











  const countryFlagFilter = (Flag: any, tag: number) => {
    return <IconButton sx={{
      "& svg": {
        width: '32px',
        height: '32px',
        opacity: filterCountries.indexOf(tag) > -1 ? 1 : 0.4
      }
    }} onClick={() => {
      if (filterCountries.indexOf(tag) > -1) {
        setFilterCountries(filterCountries.filter(fff => fff !== tag));
      } else {
        setFilterCountries([...filterCountries, tag]);
      }
    }}><Flag /></IconButton>
  }

  const PoiMarkers = (props: { pois: Poi[] }) => {
    const map = useMap();
    const [markers, setMarkers] = useState<{[key: string]: Marker}>({});
    const [showTt, setShowTt] = useState<Poi>();
    // const clusterer = useRef<MarkerClusterer | null>(null);
  
    // Initialize MarkerClusterer, if the map has changed
    // useEffect(() => {
    //   if (!map) return;
    //   if (!clusterer.current) {
    //     clusterer.current = new MarkerClusterer({map});
    //   }
    // }, [map]);

    // Update markers, if the markers array has changed
    // useEffect(() => {
    //   clusterer.current?.clearMarkers();
    //   clusterer.current?.addMarkers(Object.values(markers));
    // }, [markers]);

    const clusterer = useMemo(() => {
      if (!map) return null;
  
      return new MarkerClusterer({map});
    }, [map]);
  
    useEffect(() => {
      if (!clusterer) return;
  
      clusterer.clearMarkers();
      clusterer.addMarkers(Object.values(markers));
    }, [clusterer, markers]);
  
    const setMarkerRef = (marker: Marker | null, key: string) => {
      if (marker && markers[key]) return;
      if (!marker && !markers[key]) return;
  
      setMarkers(prev => {
        if (marker) {
          return {...prev, [key]: marker};
        } else {
          const newMarkers = {...prev};
          delete newMarkers[key];
          return newMarkers;
        }
      });
    };

    return (
      <>
        {props.pois.map( (poi: Poi) => <>
          <AdvancedMarker
            key={poi.key}
            position={poi.location}
            ref={marker => setMarkerRef(marker, poi.key)}
            onClick={(ev) => {
              setShowTt(poi);
              // map.panTo(ev.latLng);
            }}
            >
              <Pin background={theme.palette.primary.main} glyphColor={'#000'} borderColor={'#000'} />
              {/* <Tooltip title="test"><><Pin background={theme.palette.primary.main} glyphColor={'#000'} borderColor={'#000'} /></></Tooltip> */}
          </AdvancedMarker>
        </>)}
        {showTt && <InfoWindow headerContent={<h3 style={{marginTop: 0, marginBottom: 0}}>{showTt.o.name}</h3>} anchor={markers[showTt.key]} onClose={() => {setShowTt(undefined);}}>
          {!!showTt.o.practice_name && <p>{t("client.general.part_of")}: {showTt.o.practice_name}</p>}
          {!!showTt.o.is_online && <p>{t("client.appointment.online_location", "Online consultatie")}</p>}
          {!showTt.o.is_online && <p>{showTt.o.address}</p>}
          {/* @ts-ignore */}
          <p>{showTt.o.coach_list}</p>
          <p>{showTt.o.practice_full_find_teaser[HelpersLanguage.mapping_to_id(i18n.resolvedLanguage || 'nl')]}</p>
          <Button
            id="check"
            onClick={() => {
              let practice = undefined;
              (practices || []).forEach((p) => {
                if (p.id === parseInt(showTt.o.practice_id, 10)) practice = p;
              });
              setSelectedPractice(practice);
              setSelectedLocation(showTt.o);
            }}
            sx={{
              marginTop: 1
            }}
            label={t("client.dashboard.my_practice.action")}
          />
        </InfoWindow>}
      </>
    );
  };

  useEffect(() => {
    mutationCustomAction.mutate({
      formData: {
        object: 'practice',
        class_action: 'custom_api_get_find',
      }
    });
  }, []);
  useEffect(() => {
    setLocs([]);

    const timer = setTimeout(() => {
      // console.log(`search -- ${search} -- ${skills}`);
      let lll = (locations|| []).filter(ff => !!ff.lat && !!ff.lng).map(loc => {return {key: [loc.id, loc.name].join("_"), o: loc, location: { lat: parseFloat(loc.lat?.toString() || ''), lng: parseFloat(loc.lng?.toString() || '') }};}).filter(loc => (
        !search || (
          !!search && (
            loc.o.name.toLowerCase().indexOf(search.toLowerCase()) > -1 || 
            (loc.o.practice_name || '').toLowerCase().indexOf(search.toLowerCase()) > -1 || 
            // (loc.o.practice_url_path || '').toLowerCase().indexOf(search.toLowerCase()) > -1 || 
            (loc.o.address || '').toLowerCase().indexOf(search.toLowerCase()) > -1 || 
            // @ts-ignore
            (loc.o.coach_list || '').toLowerCase().indexOf(search.toLowerCase()) > -1
          )
        )
      ) && (
        skills.length < 1 || (
          // @ts-ignore
          skills.length > 0 && loc.o.practice_public_skill_ids.filter(element => skills.indexOf(element) !== -1).length > 0
        )
      ) && (
        // @ts-ignore
        filterCountries.indexOf(loc.o.practice_country_id) > -1
      ));
      // console.log(lll);
      setLocs(lll);
    }, 1000);

    return () => {
      clearTimeout(timer);
    }
  }, [locations, search, skills, filterCountries]);
  // useEffect(() => {
  //   const timerrr = setInterval(() => {
  //     setProgress((oldProgress) => {
  //       if (oldProgress === 100) {
  //         return 0;
  //       }
  //       const diff = Math.random() * 10;
  //       return Math.min(oldProgress + diff, 100);
  //     });
  //   }, 500);

  //   return () => {
  //     clearInterval(timerrr);
  //   };
  // }, []);

  return (<Page sx={{
    padding: 0
  }}>
    {!selectedPractice && mode === 'none' && <>
      <Box sx={{
        flexGrow: 1,
        height: "100vh",
        overflowY: "scroll",
        overflowX: "none",
        width: "100%",
        "-webkit-overflow-scrolling": "touch",
        // padding: theme.spacing(3)
        "&>*": {
          minWidth: 300,
          overflowX: "hidden"
        }
      }} id="scroller">
        <Fab
          onClick={() => {
            navigate('/login');
          }}
          icon={<ArrowBackIcon />}
        />
        <Box sx={{height: '100vh', overflowX: 'initial'}}>
          <Box sx={{
            backgroundPosition: "center center",
            backgroundSize: "cover",
            top: 0,
            width: "100%",
            height: "100%",
            ...(!!login ? {
              opacity: 0.2
            } : {}),
            backgroundImage: theme.palette.fancy_bg_img,
            position: "absolute"
          }}></Box>
          <Box sx={{
            backgroundColor: "rgba(255,255,255,0.55)",
            // backgroundColor: "rgba(92,198,50,0.25)",
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%"
          }}></Box>
          <Box sx={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflowY: "auto",
            [theme.breakpoints.down('sm')]: {
              alignItems: "flex-start"
            }
          }}>
            <Paper sx={{
              padding: 3,
              minHeight: 150,
              // height: '100%',
              marginTop: 8,
              width: 900,
              margin: 1,
              backgroundColor: "rgba(255,255,255,1.00)",
              [theme.breakpoints.up('sm')]: {
                maxHeight: 500,
                overflowY: "auto"
              },
              [theme.breakpoints.down('sm')]: {
                width: "100%",
                position: "fixed",
                height: "100%",
                margin: 0,
                backgroundColor: "rgba(255,255,255,0.85)",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                paddingTop: '56px',
                overflowY: "auto",
                overflowX: "hidden",
                "-webkit-overflow-scrolling": "touch"
              }
            }} id="subscroller">

              <Grid container spacing={1}>
                <Grid item xs={12} sx={{
                  textAlign: 'center'
                }}>
                  <Typography variant="h1" sx={{
                    textAlign: 'center',
                    color: theme.palette.primary.main
                  }}>{t("public.find.title")}</Typography>
                  <Typography variant="subtitle1" sx={{
                    textAlign: 'center',
                    // color: theme.palette.primary.main
                  }}>{t("public.find.description")}</Typography>
                  <Typography variant="h4" sx={{
                    textAlign: 'center',
                    color: theme.palette.primary.main,
                    marginTop: 4
                  }}>{t("public.find.subtitle")}</Typography>
                </Grid>
                <Grid item xs={12} md={4} sx={{
                  textAlign: 'center',
                }}>
                  <Box sx={{
                    width: '100%',
                    height: '250px',
                    borderRadius: '16px 16px 0px 0px',
                    backgroundImage: `url(${ImgOnline})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    cursor: 'pointer'
                  }} onClick={() => {
                    setMode('online');
                  }} />
                  <Button
                    label={t("public.find.mode.online")}
                    id="switch_online"
                    contained
                    fullwidth
                    onClick={() => {
                      setMode('online');
                    }}
                    startIcon={<VideoCallIcon />}
                    sx={{
                      marginTop: 0,
                      borderRadius: '0px 0px 16px 16px',
                      textTransform: 'none !important'
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4} sx={{
                  textAlign: 'center',
                }}>
                  <Box sx={{
                    width: '100%',
                    height: '250px',
                    borderRadius: '16px 16px 0px 0px',
                    backgroundImage: `url(${ImgPhysical})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    cursor: 'pointer'
                  }} onClick={() => {
                    setMode('phys');
                  }} />
                  <Button
                    label={t("public.find.mode.physical")}
                    id="switch_phys"
                    contained
                    fullwidth
                    onClick={() => {
                      setMode('phys');
                    }}
                    startIcon={<GroupIcon />}
                    sx={{
                      marginTop: 0,
                      borderRadius: '0px 0px 16px 16px',
                      textTransform: 'none !important'
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4} sx={{
                  textAlign: 'center',
                }}>
                  <Box sx={{
                    width: '100%',
                    height: '250px',
                    borderRadius: '16px 16px 0px 0px',
                    backgroundImage: `url(${ImgAny})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    cursor: 'pointer'
                  }} onClick={() => {
                    setMode('both');
                  }} />
                  <Button
                    label={t("public.find.mode.both")}
                    id="switch_both"
                    contained
                    fullwidth
                    onClick={() => {
                      setMode('both');
                    }}
                    startIcon={<AllInclusiveIcon />}
                    sx={{
                      marginTop: 0,
                      borderRadius: '0px 0px 16px 16px',
                      textTransform: 'none !important'
                    }}
                  />
                </Grid>
              </Grid>

            </Paper>
          </Box>
        </Box>
      </Box>
    </>}
    {!selectedPractice && mode !== 'none' && <>
      <Box sx={{width: '100%', height: '100%'}}>
        <Fab
          onClick={() => {
            setMode('none');
          }}
          icon={<ArrowBackIcon />}
        />
        <Box sx={{display: "flex", flexDirection: "column", height: "100%"}}>

          <Box sx={{
            flexGrow: 1,
            position: "relative"
          }}>
            <Box sx={{
              height: {
                xs: 200,
                md: 100
              },
              width: "100%",
              backgroundColor: "white",
              position: 'relative'
            }}>
              {progress > 0 && <Box sx={{
                width: '100%',
                position: 'absolute',
                bottom: 0,
                left: 0
              }}>
                <LinearProgress />
              </Box>}
              <Grid container sx={{
                margin: 0,
                width: '100%'
              }} spacing={2}>
                <Grid item sm={6} xs={12} sx={{
                  padding: 1,
                  paddingTop: "8px !important",
                  paddingLeft: 1
                }}>
                  <TextInput
                    id="search"
                    placeholder={t("client.find.filter_open_placeholder", "Zoek op praktijk, coach, ...")}
                    caption={t("client.find.filter_open", "Zoek")}
                    autoComplete="search"
                    autoFocus
                    backend={false}
                    value={search}
                    onChange={(e) => {
                      setSearch(e);
                      // let s = this.state.search;
                      // s = e.target.value;
                      // this.setState({search: s}, this.setMarkers(undefined, undefined, undefined, e.target.value, this.state.search_types));
                    }}
                  />
                </Grid>
                <Grid item sm={4} xs={12} sx={{
                  padding: 1,
                  paddingTop: "8px !important",
                  paddingLeft: 1
                }}>
                  {coachSkills && <MultiSelect
                    id="coach_skills"
                    caption={t("client.find.filter_type", "Filters")}
                    backend
                    value={skills}
                    chips
                    options={coachSkills}
                    setValue={(e) => {
                      setSkills(e);
                    }}
                  >
                    {/* <MenuItem value=""></MenuItem> */}
                    {(coachSkills || []).map(skill => <MenuItem value={skill.id}>{skill.name}</MenuItem>)}
                  </MultiSelect>}
                </Grid>
                <Grid item sm={2} xs={12} sx={{
                  padding: 1,
                  paddingTop: "8px !important",
                  paddingLeft: 1
                }}>
                  <Typography variant="caption" sx={{
                    // fontSize: '0.7rem',
                    width: '100%',
                    display: 'block'
                  }}>{t("client.find.filter_country", "Land")}</Typography>
                  {countryFlagFilter(BE, 1)}
                  {countryFlagFilter(NL, 2)}
                </Grid>
              </Grid>
            </Box>



            {mode === 'both' && <Box sx={{
              height: {
                sx: "calc(100vh - 200px)",
                md: "calc(100vh - 100px)"
              },
              padding: 4
            }}>
              <Grid container spacing={1} sx={{paddingBottom: 4}}>
                {!mutationCustomAction.isPending && practicesBoth.filter(prac => (
                  !search || (
                    !!search && (
                      prac.name.toLowerCase().indexOf(search.toLowerCase()) > -1 || 
                      // @ts-ignore
                      (prac.coach_list || '').toLowerCase().indexOf(search.toLowerCase()) > -1
                    )
                  )
                ) && (
                  skills.length < 1 || (
                    // @ts-ignore
                    skills.length > 0 && prac.public_skill_ids.filter(element => skills.indexOf(element) !== -1).length > 0
                  )
                ) && (
                  filterCountries.indexOf(prac.country_id || 1) > -1
                )).length < 1 && <>
                  <Grid item xs={12}>
                    <Typography>{t("shared.terms.no_results")}</Typography>
                  </Grid>
                </>}
                {practicesBoth.filter(prac => (
                  !search || (
                    !!search && (
                      prac.name.toLowerCase().indexOf(search.toLowerCase()) > -1 || 
                      // @ts-ignore
                      (prac.coach_list || '').toLowerCase().indexOf(search.toLowerCase()) > -1
                    )
                  )
                ) && (
                  skills.length < 1 || (
                    // @ts-ignore
                    skills.length > 0 && prac.public_skill_ids.filter(element => skills.indexOf(element) !== -1).length > 0
                  )
                ) && (
                  filterCountries.indexOf(prac.country_id || 1) > -1
                )).map(practice => <>
                  <Grid item xs={12}>
                    <Box sx={{
                      padding: 4,
                      paddingLeft: '232px',
                      position: 'relative',
                      borderRadius: '16px',
                      cursor: 'pointer',
                      border: '1px solid transparent',
                      borderColor: theme.palette.primary.main,
                      backgroundColor: 'white'
                    }} onClick={() => {
                      setSelectedPractice(practice);
                    }}>
                      <Box sx={{
                        width: '136px',
                        height: "calc(100% - 64px)",
                        position: 'absolute',
                        left: 32,
                        top: 32,
                        backgroundImage: `url(${practice.get_medium})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat'
                      }} />
                      <Box sx={{
                        height: '100%',
                        position: 'absolute',
                        left: 200,
                        top: 0,
                        width: '1px',
                        borderLeft: '1px solid transparent',
                        borderColor: theme.palette.primary.main,
                      }} />

                      <Typography sx={{
                        fontWeight: 'bold'
                      }}>{practice.name}</Typography>
                      <Typography>{(practice.coach_full_list || []).map(coach => <Box sx={{
                        width: 'auto',
                        display: 'inline-block',
                        border: '1px solid transparent',
                        // borderColor: theme.palette.primary.main,
                        borderRadius: '32px',
                        padding: 0,
                        position: 'relative',
                        paddingLeft: '64px',
                        paddingRight: '16px',
                        height: '48px',
                        marginRight: 4,
                        marginTop: 2,
                        marginBottom: 2
                      }}>
                        <Avatar src={coach.get_medium} sx={{
                          position: 'absolute',
                          left: 0,
                          top: 0,
                          height: '48px',
                          width: '48px'
                        }} />
                        <Typography sx={{
                          lineHeight: '48px'
                        }}>{[coach.first_name, coach.last_name].join(" ")}</Typography>
                      </Box>)}</Typography>
                      <Typography>{(practice.location_list || []).filter(fff => !!fff.zip && !!fff.city).map(lll => [lll.zip, lll.city].join(' ')).filter((value, index, array) => array.indexOf(value) === index).join(", ")}</Typography>
                      <Typography>{(practice.public_skill_ids || []).map(sss => (((coachSkills || []).filter(fff => fff.id === sss) || [])[0] || {}).name).sort().map(skill => <Chip
                        color="primary"
                        size="small"
                        variant="outlined"
                        label={skill}
                        sx={{
                          marginRight: 0.5,
                          marginBottom: 0.5,
                          marginTop: 0.5
                        }}
                      />)}</Typography>
                      {!!(practice.full_find_teaser || {})[HelpersLanguage.mapping_to_id(i18n.resolvedLanguage || 'nl')] && <Typography sx={{
                        paddingLeft: 2,
                        marginTop: 2,
                        borderLeft: '2px solid transparent',
                        borderColor: theme.palette.primary.main,
                        color: 'grey',
                        fontStyle: 'italic'
                      }}>{(practice.full_find_teaser || {})[HelpersLanguage.mapping_to_id(i18n.resolvedLanguage || 'nl')]}</Typography>}
                    </Box>
                  </Grid>
                </>)}
              </Grid>
            </Box>}



            {mode === 'online' && <Box sx={{
              height: {
                sx: "calc(100vh - 200px)",
                md: "calc(100vh - 100px)"
              },
              padding: 4
            }}>
              <Grid container spacing={1} sx={{paddingBottom: 4}}>
                {!mutationCustomAction.isPending && practicesOnline.filter(prac => (
                  !search || (
                    !!search && (
                      prac.name.toLowerCase().indexOf(search.toLowerCase()) > -1 || 
                      // @ts-ignore
                      (prac.coach_list || '').toLowerCase().indexOf(search.toLowerCase()) > -1
                    )
                  )
                ) && (
                  skills.length < 1 || (
                    // @ts-ignore
                    skills.length > 0 && prac.public_skill_ids.filter(element => skills.indexOf(element) !== -1).length > 0
                  )
                ) && (
                  filterCountries.indexOf(prac.country_id || 1) > -1
                )).length < 1 && <>
                  <Grid item xs={12}>
                    <Typography>{t("shared.terms.no_results")}</Typography>
                  </Grid>
                </>}
                {practicesOnline.filter(prac => (
                  !search || (
                    !!search && (
                      prac.name.toLowerCase().indexOf(search.toLowerCase()) > -1 || 
                      // @ts-ignore
                      (prac.coach_list || '').toLowerCase().indexOf(search.toLowerCase()) > -1
                    )
                  )
                ) && (
                  skills.length < 1 || (
                    // @ts-ignore
                    skills.length > 0 && prac.public_skill_ids.filter(element => skills.indexOf(element) !== -1).length > 0
                  )
                ) && (
                  filterCountries.indexOf(prac.country_id || 1) > -1
                )).map(practice => <>
                  <Grid item xs={12}>
                    <Box sx={{
                      padding: 4,
                      paddingLeft: '232px',
                      position: 'relative',
                      borderRadius: '16px',
                      cursor: 'pointer',
                      border: '1px solid transparent',
                      borderColor: theme.palette.primary.main,
                      backgroundColor: 'white'
                    }} onClick={() => {
                      setSelectedPractice(practice);
                    }}>
                      <Box sx={{
                        width: '136px',
                        height: "calc(100% - 64px)",
                        position: 'absolute',
                        left: 32,
                        top: 32,
                        backgroundImage: `url(${practice.get_medium})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat'
                      }} />
                      <Box sx={{
                        height: '100%',
                        position: 'absolute',
                        left: 200,
                        top: 0,
                        width: '1px',
                        borderLeft: '1px solid transparent',
                        borderColor: theme.palette.primary.main,
                      }} />

                      <Typography sx={{
                        fontWeight: 'bold'
                      }}>{practice.name}</Typography>
                      <Typography>{(practice.coach_full_list || []).map(coach => <Box sx={{
                        width: 'auto',
                        display: 'inline-block',
                        border: '1px solid transparent',
                        // borderColor: theme.palette.primary.main,
                        borderRadius: '32px',
                        padding: 0,
                        position: 'relative',
                        paddingLeft: '64px',
                        paddingRight: '16px',
                        height: '48px',
                        marginRight: 4,
                        marginTop: 2,
                        marginBottom: 2
                      }}>
                        <Avatar src={coach.get_medium} sx={{
                          position: 'absolute',
                          left: 0,
                          top: 0,
                          height: '48px',
                          width: '48px'
                        }} />
                        <Typography sx={{
                          lineHeight: '48px'
                        }}>{[coach.first_name, coach.last_name].join(" ")}</Typography>
                      </Box>)}</Typography>
                      {/* <Typography>{(practice.location_list || []).map(lll => lll.name).join(", ")}</Typography> */}
                      <Typography>{(practice.public_skill_ids || []).map(sss => (((coachSkills || []).filter(fff => fff.id === sss) || [])[0] || {}).name).sort().map(skill => <Chip
                        color="primary"
                        size="small"
                        variant="outlined"
                        label={skill}
                        sx={{
                          marginRight: 0.5,
                          marginBottom: 0.5,
                          marginTop: 0.5
                        }}
                      />)}</Typography>
                      {!!(practice.full_find_teaser || {})[HelpersLanguage.mapping_to_id(i18n.resolvedLanguage || 'nl')] && <Typography sx={{
                        paddingLeft: 2,
                        marginTop: 2,
                        borderLeft: '2px solid transparent',
                        borderColor: theme.palette.primary.main,
                        color: 'grey',
                        fontStyle: 'italic'
                      }}>{(practice.full_find_teaser || {})[HelpersLanguage.mapping_to_id(i18n.resolvedLanguage || 'nl')]}</Typography>}
                    </Box>
                  </Grid>
                </>)}
              </Grid>
            </Box>}



            {mode === 'phys' && <Box sx={{
              height: {
                sx: "calc(100vh - 200px)",
                md: "calc(100vh - 100px)"
              }
            }}>
              <Box sx={{
                width: "100%",
                float: "left",
                height: "100%"
              }}>
                <APIProvider apiKey="AIzaSyDC66MlnQH4MnMsDSQIu4zZw8VGQ0SpUxI">
                  {locs.length === 0 && <Map
                    style={{width: '100%', height: '100%'}}
                    defaultCenter={{
                      lat: 51.5831,
                      lng: 4.777
                    }}
                    defaultZoom={8}
                    gestureHandling={'greedy'}
                    disableDefaultUI
                    mapId='131f9f3fb5a68ab2'
                  ></Map>}
                  {locs.length > 0 && <Map
                    style={{width: '100%', height: '100%'}}
                    defaultCenter={{
                      lat: 51.5831,
                      lng: 4.777
                    }}
                    defaultZoom={8}
                    gestureHandling={'greedy'}
                    disableDefaultUI
                    mapId='131f9f3fb5a68ab2'
                  >
                    {locs.length > 0 && <PoiMarkers pois={locs} />}
                  </Map>}
                </APIProvider>
              </Box>
            </Box>}
          </Box>

        </Box>
      </Box>
    </>}






    {!!selectedPractice && <>
      <Box sx={{
        flexGrow: 1,
        height: (!!login ? '100%' : '100vh'),
        overflowY: (!!login ? 'auto' : 'scroll'),
        overflowX: "none",
        width: "100%",
        "-webkit-overflow-scrolling": "touch",
        padding: 4
      }} id="scroller">
        {!!selectedPractice?.is_allow_guest_accounts && <Fab
          onClick={() => {
            // create appointment
            window.open(`https://${selectedPractice?.url_path}.nutriportal.eu/appointment`, "_BLANK");
          }}
          icon={<EventIcon />}
          sx={{
            bottom: 160
          }}
        />}
        <Fab
          onClick={() => {
            // create appointment
            window.open(`https://${selectedPractice?.url_path}.nutriportal.eu`, "_BLANK");
          }}
          icon={<LaunchIcon />}
          sx={{
            bottom: 88
          }}
        />
        <Fab
          onClick={() => {
            setSelectedPractice(undefined);
            setSelectedLocation(undefined);
          }}
          icon={<ArrowBackIcon />}
        />
        
        <Grid container spacing={2}>

          <Grid item sm={4} xs={12}>
            <Paper sx={{
              padding: 2
            }}>
              <PracticeLogoAvatar practice={selectedPractice} showName />
            </Paper>

            <Paper sx={{
              padding: 2,
              marginTop: 2
            }}>
              {!!login && <Button
                label={t("client.general.buttons.choose", "Kies deze praktijk")}
                id='choose'
                contained
                fullwidth
                onClick={(v) => {
                  mutationSubscribe.mutate({
                    formData: {
                      object: "user",
                      class_action: 'custom_api_sub_practice',
                      id: login?.id,
                      practice_id: selectedPractice.id
                    }
                  });
                }}
                sx={{
                  marginTop: 0,
                  marginBottom: 3
                }}
              />}
              {!!selectedPractice?.is_allow_guest_accounts && <Button
                label={t("shared.buttons.make_appointment")}
                id='make_appointment'
                contained
                fullwidth
                onClick={(v) => {
                  window.open(`https://${selectedPractice?.url_path}.nutriportal.eu/appointment`, "_BLANK");
                }}
                sx={{
                  marginTop: 0,
                  marginBottom: 3
                }}
              />}
              <Button
                label={t("shared.buttons.visit_portal")}
                id='visit_portal'
                contained
                fullwidth
                onClick={(v) => {
                  window.open(`https://${selectedPractice?.url_path}.nutriportal.eu`, "_BLANK");
                }}
                sx={{
                  marginTop: 0,
                }}
              />
            </Paper>
          </Grid>

          {((selectedPractice.full_intro || {})[HelpersLanguage.mapping_to_id(i18n.resolvedLanguage || 'nl')] || '').trim().length > 0 && <Grid item sm={8} xs={12}>
            <Paper sx={{
              padding: 2
            }}>
              <Typography html>{(selectedPractice.full_intro || {})[HelpersLanguage.mapping_to_id(i18n.resolvedLanguage || 'nl')]}</Typography>
            </Paper>
          </Grid>}
          {((selectedPractice.full_intro || {})[HelpersLanguage.mapping_to_id(i18n.resolvedLanguage || 'nl')] || '').trim().length < 1 && ((selectedPractice.full_my_practice || {})[HelpersLanguage.mapping_to_id(i18n.resolvedLanguage || 'nl')] || '').trim().length > 0 && <Grid item sm={8} xs={12}>
            <Paper sx={{
              padding: 2
            }}>
              <Typography html>{(selectedPractice.full_my_practice || {})[HelpersLanguage.mapping_to_id(i18n.resolvedLanguage || 'nl')]}</Typography>
            </Paper>
          </Grid>}

          {!!selectedPractice?.settings?.show_reviews_on_frontend && (questionnaireResults || []).length > 0 && <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>{t("general.login_dashboard.reviews", "Reviews")}</Typography>
            <ImageList cardWidth={400}>
              {(questionnaireResults || []).map(questionnaireResult => <ImageListItem key={questionnaireResult.id} sx={{
                height: "auto !important"
              }}>
                <CardReview
                  questionnaireResult={questionnaireResult}
                  sx={{
                    width: '400px'
                  }}
                />
              </ImageListItem>)}
            </ImageList>
          </Grid>}

          {!!selectedPractice.coach_full_list && <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>{t("client.my_practice.coaches")}</Typography>
            <ImageList cardWidth={400}>
              {(selectedPractice.coach_full_list || []).map(coach => <ImageListItem key={coach.id} sx={{
                height: "auto !important",
              }}>
                <CardCoach
                  coach={coach}
                />
              </ImageListItem>)}
            </ImageList>
          </Grid>}

          {!!selectedPractice.location_list && <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>{t("client.my_practice.locations")}</Typography>
            <ImageList cardWidth={400}>
              {(selectedPractice.location_list || []).map(location => <ImageListItem key={location.id} sx={{
                height: "auto !important",
              }}>
                <CardLocation
                  location={location}
                />
              </ImageListItem>)}
            </ImageList>
          </Grid>}
        </Grid>
      </Box>
    </>}
  </Page>);
}
