import React, { FunctionComponent, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all, fetch_all_with_count } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
import { currentPracticeAtom } from '../../lib/practice';

import LaunchIcon from '@mui/icons-material/Launch';

import {
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  MenuItem
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  Button,
  DataGrid,
  Dt,
  Fab,
  FileUpload,
  IconButton,
  Page,
  Select,
  Typography,
} from '../../components/v2/styled';
import { SnackDestroyed } from '../../components/v2/snacks/Destroyed';
import { SnackSaved } from '../../components/v2/snacks/Saved';

// import { Import } from '../../models/Import';

// const { DateTime } = require("luxon");

import HelpersLanguage from '../../actions/helpers/language';

type Props = {}

export const AdminTranslationFiles: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const [destroyedOpen, setDestroyedOpen] = useState<boolean>(false);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [isImport, setIsImport] = useState<boolean>(false);
  const [languageId, setLanguageId] = useState<number>(1);
  const [newImport, setNewImport] = useState<{
    item?: File
  }>();
  
  let count = 0;
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("created_at DESC");

  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["admin", "translation_files", {page: page, order: order}],
    queryFn: () =>
      fetch_all_with_count<{
        get_item: string;
        created_at: string;
        item_file_name: string;
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'translation_file',
          fields: ['id', 'get_item', 'item_file_name', 'created_at'],
          order: order,
          per_page: 20,
          page: page,
          // filter: {
          //   search: '',
          //   advanced: {
          //     practice_id: (login?.practice_id || currentPractice?.id)
          //   }
          // }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: true,
  });








  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: {
          error?: string;
          url?: string;
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setNewImport(undefined);
      objectsRefetch();
    },
  });







  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'created_at', headerName: t('admin.models.imports.created_at', 'Aangemaakt'), width: 200, renderCell: params => <>
      <Dt d={params.row.created_at} f="DDD t" />
    </> },
    { field: 'item_file_name', headerName: t('admin.models.translation_files.item_file_name', 'Filename'), width: 400 },
  ];

  return <Page title={t("admin.menu.translation_files", "Transl. Files")}>
    {!!newImport && <>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Select
            fullWidth
            caption={t("admin.translation_files.fields.type", "Import/Export")}
            backend
            id="type"
            value={!!isImport ? '1' : '0'}
            setValue={(v) => {
              setIsImport(v === '1');
            }}
          >
            <MenuItem value="0">{t("admin.translation_files.fields.types.export", "Export")}</MenuItem>
            <MenuItem value="1">{t("admin.translation_files.fields.types.import", "Import")}</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Select
            fullWidth
            caption={t("admin.translation_files.fields.language", "Taal")}
            backend
            id="type"
            value={languageId.toString()}
            setValue={(v) => {
              setLanguageId(parseInt(v.toString(), 10));
            }}
          >
            {HelpersLanguage.available_locales().map(language => <MenuItem value={HelpersLanguage.mapping_to_id(language).toString()}>{language}</MenuItem>)}
          </Select>
        </Grid>
        {!!isImport && <Grid item xs={4}>
          <FileUpload
            caption={t("admin.models.translation_files.item")}
            fileInputName='item'
            selected={newImport.item}
            onChange={(f) => {
              setNewImport({
                ...newImport,
                item: f as File
              });
            }}
          />
        </Grid>}

        
        <Grid item xs={12} sx={{
          alignContent: 'baseline',
          textAlign: 'center',
          position: {
              xs: 'relative',
              md: 'sticky'
            },
          bottom: '-24px',
          backgroundColor: 'white',
          zIndex: 1201
        }}>
          <Divider sx={{marginTop: 1, marginBottom: 0}} />

          {!!mutationCustomAction.isPending && <CircularProgress />}
          {!mutationCustomAction.isPending && <Button
            label={t("shared.buttons.save")}
            id='save'
            contained
            onClick={(v) => {
              let data = new FormData();
              data.append('object', 'translation_file');
              if (!!isImport) data.append('class_action', 'custom_api_import');
              if (!isImport) data.append('class_action', 'custom_api_export');
              data.append('handler_id', login?.id?.toString() || '');
              if (!!isImport && !!newImport.item) {
                data.append(`item`, newImport.item);
              }
              data.append(`language_id`, languageId.toString());
              data.append(`object_name`, 'Static');
              
              mutationCustomAction.mutate({
                formData: data
              });
            }}
            sx={{marginBottom: 1, marginTop: 1}}
          />}
          {!mutationCustomAction.isPending && <Button
            label={t("shared.buttons.cancel")}
            id='cancel'
            text
            // contained
            onClick={(v) => {
              setNewImport(undefined);
            }}
            sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
          />}
        </Grid>
      </Grid>
    </>}

    {!newImport && <DataGrid
      action_count={1}
      actions={(params) => {
        return (<>
          <IconButton title="Download" color="default" onClick={() => {
            window.open(params.row.get_item);
          }}>
            <LaunchIcon />
          </IconButton>
        </>);
      }}
      objectCount={count}
      getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
      objects={(objects || []).map(u => ({
        ...u
      }))}
      columns={columns}
      loader={objectsLoading}
      onSortModelChange={(a, b) => {
        let a_0_field:string = a[0].field;
        if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
        setOrder([a_0_field, a[0].sort].join(' '));
      }}
      page={page}
      setPage={(p) => {setPage(p);}}
    />}

    <SnackDestroyed open={destroyedOpen} setOpen={setDestroyedOpen} />
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />

    {!newImport && <Fab
      onClick={() => {
        // navigate("/export/new", {});
        setNewImport({});
      }}
    />}
  </Page>;
}
