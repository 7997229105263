import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all, fetch_one } from "../../../../lib/v31lib";
import { loginAtom } from '../../../../lib/auth';
import { currentPracticeAtom } from '../../../../lib/practice';

import EmailIcon from '@mui/icons-material/Email';
import FlagIcon from '@mui/icons-material/Flag';
import PersonIcon from '@mui/icons-material/Person';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

import {
  Avatar,
  Box,
  CardContent,
  Checkbox,
  CircularProgress,
  Grid,
  List,
  MenuItem,
  Paper,
} from '@mui/material';

import {
  Button,
  DatePicker,
  Select,
  Switcher,
  TextInput,
  Typography
} from '../../../../components/v2/styled';
import { CardBase } from '../../../../components/v2/cards/Base';
import { ListItemAppointmentType } from '../../../../components/v2/list_items/AppointmentType';
import { ListItemCoach } from '../../../../components/v2/list_items/Coach';
import { ListItemLocation } from '../../../../components/v2/list_items/Location';
import { ListItemTime } from '../../../../components/v2/list_items/Time';

import { AppointmentType } from '../../../../models/AppointmentType';
import { Country } from '../../../../models/Country';
import { Location } from '../../../../models/Location';
import { User } from '../../../../models/User';


import HelpersLanguage from '../../../../actions/helpers/language';
import HelpersPractice from '../../../../actions/helpers/practice';

// const { DateTime } = require("luxon");

type Props = {
  noCoach: boolean;
  noLocation: boolean;
  selectedCoach?: User;
  selectedLocation?: Location;
  selectedType: AppointmentType;
  selectedSlot?: [string, Location, User];
  appointment: {
    note: string,
    reminder_mail: boolean,
    reminder_sms: boolean
  };
  validatedUser?: User;
  validUser?: boolean;
  setAppointment: (s:{
    note: string,
    reminder_mail: boolean,
    reminder_sms: boolean
  }) => void;
  setValidatedUser: (u:User) => void;
  setValidUser: (b:boolean) => void;
  setNewUser: (u:User|undefined) => void;
}

export const ClientAppointmentNewStepConfirm: FunctionComponent<Props> = ({setNewUser, validUser, setValidUser, selectedType, selectedCoach, selectedLocation, noCoach, noLocation, selectedSlot, appointment, setAppointment, validatedUser, setValidatedUser}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  // const matchesmd = useMediaQuery(theme.breakpoints.up('md'));

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [showLogin, setShowLogin] = useState<boolean>(false);
  // const [currentTab, setCurrentTab] = useState<number>(0);
  const [isLoginError, setIsLoginError] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<{
    success: boolean;
    data: any;
  }>();
  const [errors, setErrors] = useState<{[z:string]: boolean}>({});
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [birthDate, setBirthDate] = useState<string>('');
  const [passwordRepeat, setPasswordRepeat] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [mobile, setMobile] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [item, setItem] = useState<File>();
  const [emailExists, setEmailExists] = useState<boolean>(false);
  const [gdprAccepted, setGdprAccepted] = useState<boolean>(false);
  const [practiceGdprAccepted, setPracticeGdprAccepted] = useState<boolean>(false);
  const [showPracticeGdpr, setShowPracticeGdpr] = useState<boolean>(false);
  const [isGuest, setIsGuest] = useState<boolean>(false);

  const {
    data: countries,
    isLoading: countriesLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["countries", "select_list"],
    queryFn: () =>
      fetch_all<Country>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'country',
          fields: ['id', 'name', 'iso'],
          per_page: 20,
          page: 0,
          order: "name ASC"
        }
      ),
    enabled: true,
  });






  const mutationCheckEmail = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{exists: boolean}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/check_email`,
        pars.formData,
        undefined
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsLoginError(false);
      // setLoginError(undefined);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsLoginError(true);
      // setLoginError(data as any);
      return true;
    },
    onSuccess: (data, variables, context) => {
      setEmailExists(data.exists);
    },
  });
  const mutationLogin = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/users/login`,
        pars.formData,
        undefined
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      setIsLoginError(false);
      setLoginError(undefined);
      return true;
    },
    onError: (data, variables, context) => {
      setIsLoginError(true);
      setLoginError(data as any);
      return true;
    },
    onSuccess: (data, variables, context) => {
      // setLogin(data);
      // set_login(data.email, data.auth_token);
      // navigate('/');
      setValidatedUser(data);
    },
  });






  let is_payments = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'payments'});

  useEffect(() => {
    let to = setTimeout(() => {
      if (!showLogin && !!email) {
        // check email existence
        mutationCheckEmail.mutate({formData: {email: email}});
      }
    }, 1000);

    return () => {
      clearTimeout(to);
    }
  }, [email]);
  useEffect(() => {
    if (!showLogin) {
      if (
        !!firstName && 
        !!lastName && 
        !!email && !!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email) &&
        !emailExists && 
        !!birthDate && 
        !!country && 
        mobile && 
        (!!isGuest || (!isGuest && !!password && password.length > 7 && password === passwordRepeat)) && 
        !!gdprAccepted && 
        (!currentPractice?.get_description || (!!currentPractice?.get_description && !!practiceGdprAccepted))
      ) {
        setErrors({});
        setValidUser(true);
        setNewUser({
          first_name: firstName,
          last_name: lastName,
          email: email,
          country_id: parseInt(country, 10),
          birth_date: birthDate,
          phone_mobile: mobile,
          password: password,
          password_repeat: passwordRepeat,
          is_agreed: !!gdprAccepted,
          is_agreed_practice: (!!currentPractice?.get_description && !!practiceGdprAccepted),
          practice_id: currentPractice?.id,
          auth_token: '',
          coach_skills: [],
          extra_data: {
            is_guest: isGuest
          }
        });
      } else {
        setErrors({
          first_name: !firstName,
          last_name: !lastName,
          email: !email || !!emailExists || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email),
          country: !country,
          birth_date: !birthDate,
          phone_mobile: !mobile,
          password: !password || password.length < 8,
          password_repeat: password !== passwordRepeat,
          gdpr: !gdprAccepted,
          gdpr_practice: (!!currentPractice?.get_description && !practiceGdprAccepted)
        });
        setValidUser(false);
        setNewUser(undefined);
      }
    } else {
      setValidUser(false);
      setNewUser(undefined);
    }
  }, [showLogin, firstName, lastName, email, emailExists, birthDate, country, mobile, isGuest, password, passwordRepeat, gdprAccepted, practiceGdprAccepted]);

  return <Paper sx={{
    padding: 2
  }}>
    <Box>
      <Typography variant={"subtitle2"}>{t("client.appointment.confirm_title")}:</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {!!selectedSlot && <CardBase>
            <CardContent>
              <Typography sx={{
                color: theme.palette.tertiary?.main,
                marginBottom: 1
              }}>{t("client.appointment.step.details", "Afspraakdetails")}</Typography>

              <List>
                <ListItemLocation location={selectedSlot[1]} divider />
                <ListItemCoach coach={selectedSlot[2]} divider />
                <ListItemAppointmentType type={selectedType} divider extended />
                <ListItemTime time={selectedSlot[0]} f="dd/LL/y t" />
              </List>
            </CardContent>
          </CardBase>}
        </Grid>

        <Grid item xs={12} sx={{
          marginTop: 1,
          width: '100%'
        }}>
          <CardBase>
            <CardContent>
              <Typography sx={{
                color: theme.palette.tertiary?.main,
                marginBottom: 1
              }}>{t("client.appointment.step.note", "Extra opmerking")}</Typography>
              
              <TextInput
                id="note"
                placeholder={t("client.appointment.note_placeholder", "Vul hier eventuele opmerkingen in")}
                // caption={t("client.appointment.step.note", "Extra opmerking")}
                autoFocus={false}
                error={!!selectedType.note_required && appointment.note.length < 6 ? t("shared.signup.error.comment") : undefined}
                value={appointment.note}
                multiline
                onChange={(e) => {
                  setAppointment({...appointment, note: e});
                }}
              />
            </CardContent>
          </CardBase>
        </Grid>

        <Grid item xs={12} sx={{
          marginTop: 1,
          width: '100%'
        }}>
          <CardBase>
            <CardContent>
              <Typography sx={{
                color: theme.palette.tertiary?.main,
                marginBottom: 1
              }}>{t("client.appointment.step.reminders", "Herinneringen")}</Typography>

              <Switcher
                id="reminder_mail"
                caption={t("client.appointment.reminders.email", "Email herinneringen ontvangen?")}
                backend
                value={appointment.reminder_mail}
                onChange={(e) => {
                  setAppointment({...appointment, reminder_mail: e});
                }}
              />

              {!!currentPractice?.settings?.is_sms_enabled && <Switcher
                id="reminder_sms"
                caption={t("client.appointment.reminders.sms", "SMS herinneringen ontvangen?")}
                backend
                value={appointment.reminder_sms}
                onChange={(e) => {
                  setAppointment({...appointment, reminder_sms: e});
                }}
              />}
            </CardContent>
          </CardBase>
        </Grid>

        {!login && <Grid item sm={12} sx={{
          marginTop: 1,
          width: '100%'
        }}>
          <CardBase>
            <CardContent>
              <Typography sx={{
                color: theme.palette.tertiary?.main,
                marginBottom: 1
              }}>{t("client.appointment.step.account", "Aanmelden of registreren")}</Typography>

              {!validatedUser && <Switcher
                id="register"
                caption={t("client.appointment.step.have_account", "Ik heb reeds een account")}
                backend
                value={showLogin}
                onChange={(e) => {
                  setShowLogin(e);
                }}
              />}
              {!!showLogin && <Grid container spacing={1}>
                {mutationLogin.isPending && <Grid item xs={12} sx={{
                  textAlign: "center",
                  height: 150
                }}>
                  <CircularProgress sx={{margin: 2}} />
                </Grid>}
                {!mutationLogin.isPending && <Grid item xs={0} sm={2} md={3} lg={4} xl={5}></Grid>}
                {!mutationLogin.isPending && !!validatedUser && <Grid item container spacing={1} xs={12} sm={8} md={6} lg={4} xl={2}>
                  <Grid item xs={12}>
                    <Box sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      display: "flex",
                      flex: 1
                    }}>
                      <Avatar sx={{
                        height: 120,
                        width: 120
                      }} src={validatedUser.get_medium}></Avatar>
                      <Typography variant="subtitle1" sx={{
                        marginTop: 1.5,
                        fontSize: "1.3rem",
                        fontWeight: 'bold'
                      }}>{validatedUser.full_name}</Typography>
                    </Box>
                  </Grid>
                </Grid>}
                {!mutationLogin.isPending && !validatedUser && <Grid item container spacing={1} xs={12} sm={8} md={6} lg={4} xl={2}>
                  <Grid item xs={12}>
                    <TextInput
                      error={!!isLoginError ? t("email_error") : undefined}
                      muiIcon={<EmailIcon
                        sx={{
                          color: "rgb(195,198,197)"
                        }}
                      />}
                      id="email"
                      placeholder={t("shared.signin.field.email")}
                      autoComplete="email"
                      autoFocus={false}
                      value={email}
                      onChange={(v) => {setEmail(v);}}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      error={!!isLoginError ? t("password_error") : undefined}
                      icon={"fa-key"}
                      id="password"
                      placeholder={t("shared.signin.field.password")}
                      type="password"
                      autoComplete="current-password"
                      autoFocus={false}
                      value={password}
                      onChange={(v) => {setPassword(v);}}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      label={t("shared.buttons.sign_in")}
                      id='login'
                      contained
                      disabled={!email || !password || !(!!email && email.indexOf('@') > -1 && email.indexOf('.') > -1)}
                      fullwidth
                      onClick={(v) => {
                        mutationLogin.mutate({
                          formData: {
                            fields: ['id', 'full_name', 'get_medium'],
                            email: email,
                            password: password
                          }
                        });
                      }}
                    />
                  </Grid>
                </Grid>}
                {!mutationLogin.isPending && <Grid item xs={0} sm={2} md={3} lg={4} xl={5}></Grid>}
              </Grid>}

              {!showLogin && <Box>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      error={!!errors.first_name ? t("shared.signup.error.first_name") : undefined}
                      muiIcon={<PersonIcon
                        sx={{
                          color: "rgb(195,198,197)"
                        }}
                      />}
                      id="first_name"
                      placeholder={t("shared.signup.field.first_name")}
                      autoComplete="fname"
                      autoFocus={false}
                      required
                      value={firstName || ''}
                      onChange={(v) => {
                        setFirstName(v);
                        setErrors({
                          ...errors,
                          first_name: false
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      error={!!errors.last_name ? t("shared.signup.error.last_name") : undefined}
                      muiIcon={<PersonIcon
                        sx={{
                          color: "rgb(195,198,197)"
                        }}
                      />}
                      id="last_name"
                      placeholder={t("shared.signup.field.last_name")}
                      autoComplete="lname"
                      required
                      value={lastName}
                      onChange={(v) => {
                        setLastName(v);
                        setErrors({
                          ...errors,
                          last_name: false
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      muiIcon={<EmailIcon
                        sx={{
                          color: "rgb(195,198,197)"
                        }}
                      />}
                      error={emailExists ? t("shared.signup.error.email_exist") : (!!errors.email ? t("shared.signup.error.email") : undefined)}
                      id="email"
                      placeholder={t("shared.signup.field.email")}
                      autoComplete="email"
                      type="email"
                      required
                      value={email}
                      onChange={(v) => {
                        setEmail(v);
                        setErrors({
                          ...errors,
                          email: false
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      label={t("shared.signup.field.birth_date")}
                      error={!!errors.birth_date ? t("shared.signup.error.birth_date") : undefined}
                      disableFuture
                      openTo="year"
                      format="DD"
                      views={["year", "month", "day"]}
                      id="birth_day"
                      value={birthDate}
                      setValue={(v) => {
                        setBirthDate(v);
                        setErrors({
                          ...errors,
                          birth_date: false
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Select
                      fullWidth
                      displayEmpty
                      id="country"
                      icon={<FlagIcon sx={{
                        color: "rgb(195,198,197)",
                        marginRight: 1,
                        zIndex: 900
                      }} />}
                      error={!!errors.country ? t("shared.signup.error.country") : undefined}
                      value={country}
                      setValue={(v) => {
                        setCountry(v.toString());
                        setErrors({
                          ...errors,
                          country: false
                        });
                      }}
                    >
                      <MenuItem value="">{t("shared.signup.field.country")}</MenuItem>
                      {(countries || []).map((country) => (<MenuItem value={country.iso}>{country.name}</MenuItem>))}
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      muiIcon={<PhoneAndroidIcon
                        sx={{
                          color: "rgb(195,198,197)"
                        }}
                      />}
                      error={!!errors.phone_mobile ? t("shared.signup.error.phone_mobile") : undefined}
                      id="phone_mobile"
                      placeholder={t("shared.signup.field.phone_mobile")}
                      autoComplete="phone_mobile"
                      type="tel"
                      required
                      value={mobile}
                      onChange={(v) => {
                        setMobile(v);
                        setErrors({
                          ...errors,
                          phone_mobile: false
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Switcher
                      id="is_guest"
                      caption={t("client.appointment.is_guest")}
                      backend
                      value={isGuest}
                      onChange={(c) => {setIsGuest(c);}}
                    />
                  </Grid>
                  {!isGuest && <Grid item xs={12} sm={5}>
                    <TextInput
                      helperText={t("shared.signup.field.password_tip")}
                      error={!!errors.password ? t("shared.signup.error.password") : undefined}
                      icon={"fa-key"}
                      id="password"
                      placeholder={t("shared.signup.field.password")}
                      type="password"
                      autoComplete="new-password"
                      autoFocus={false}
                      value={password}
                      onChange={(v) => {
                        setPassword(v);
                        setErrors({
                          ...errors,
                          phone_mobile: false
                        });
                      }}
                    />
                  </Grid>}
                  {!isGuest && <Grid item xs={6} sm={5}>
                    <TextInput
                      error={!!errors.password_repeat ? t("shared.signup.error.password_repeat") : undefined}
                      icon={"fa-key"}
                      id="password_repeat"
                      placeholder={t("shared.signup.field.password_repeat")}
                      type="password"
                      autoComplete="repeat-password"
                      autoFocus={false}
                      value={passwordRepeat}
                      onChange={(v) => {
                        setPasswordRepeat(v);
                        setErrors({
                          ...errors,
                          phone_mobile: false
                        });
                      }}
                    />
                  </Grid>}
                  <Grid item xs={12} sx={{
                    display: "flex",
                    alignItems: "center",
                    "& > label": {
                      padding: 1
                    },
                    "&.error": {
                      border: "0.5px solid rgba(255, 0, 0, 0.5)"
                    }
                  }}>
                    <Checkbox
                      checked={gdprAccepted}
                      onChange={(e) => {
                        setGdprAccepted(e.target.checked);
                        setErrors({
                          ...errors,
                          gdpr: false
                        });
                      }}
                      value="gdpr"
                    />
                    {!errors.gdpr && <Typography html>{t("client.register.gdpr_nutrilink")}</Typography>}
                    {!!errors.gdpr && <Typography html sx={{color: theme.palette.secondary.main}}>{t("client.register.gdpr_nutrilink")}</Typography>}
                  </Grid>
                  {!!currentPractice?.get_description && <>
                    <Grid item xs={12} sx={{
                      alignItems: "center",
                      display: "flex",
                      "& > label": {
                        padding: 1
                      },
                      "&.error": {
                        border: "0.5px solid rgba(255, 0, 0, 0.5)"
                      }
                    }}>
                      <Checkbox
                        checked={practiceGdprAccepted}
                        onChange={(e) => {
                          setPracticeGdprAccepted(e.target.checked);
                          setErrors({
                            ...errors,
                            gdpr_practice: false
                          });
                        }}
                        value="gdpr"
                      />
                      {!errors.gdpr_practice && <Typography html>{t("client.register.gdpr_practice").replace("{practice_name}", currentPractice.name)}</Typography>}
                      {!!errors.gdpr_practice && <Typography html sx={{color: theme.palette.secondary.main}}>{t("client.register.gdpr_practice").replace("{practice_name}", currentPractice.name)}</Typography>}
                    </Grid>
                    <Grid item xs={12} sx={{display: "flex"}}>
                      <Button
                        contained
                        id="toggle_gdpr"
                        size="small"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowPracticeGdpr(!showPracticeGdpr);
                        }}
                        label={t(showPracticeGdpr ? "client.register.gdpr_practice_toggle_off" : "client.register.gdpr_practice_toggle_on")}
                      />
                    </Grid>
                    {!!showPracticeGdpr && <Grid item xs={12} sx={{display: "flex"}}>
                      <Typography html>{currentPractice.get_description}</Typography>
                    </Grid>}
                  </>}

                </Grid>
              </Box>}
            </CardContent>
          </CardBase>
        </Grid>}

        {is_payments && !!currentPractice?.settings?.mollie_api_key && !!selectedType.mollie?.enabled && <Grid item xs={12} sx={{
          marginTop: 1,
          width: '100%'
        }}>
          <CardBase>
            <CardContent>
              <Typography sx={{
                color: theme.palette.tertiary?.main,
                marginBottom: 1
              }}>{t("client.appointment.step.payments", "Betaling")}</Typography>
              
              {!currentPractice.settings.mollie_description && <Typography>{t("client.appointment.step.payments_explanation", "De praktijk voorziet de mogelijkheid om deze afspraak online te betalen. De betaal-link kan u terugvinden in de bevestigings-email die verzonden zal worden na het bevestigen onderaan.")}</Typography>}
              {!!currentPractice.settings.mollie_description && HelpersLanguage.mapping_to_id((i18n.resolvedLanguage || 'nl').toLowerCase().split("-")[0]) === 1 && <Typography>{currentPractice.settings.mollie_description}</Typography>}
              {!!currentPractice.settings.mollie_description && HelpersLanguage.mapping_to_id((i18n.resolvedLanguage || 'nl').toLowerCase().split("-")[0]) !== 1 && <Typography>{(currentPractice.translation?.mollie_description || {})[HelpersLanguage.mapping_to_id((i18n.resolvedLanguage || 'nl').toLowerCase().split("-")[0])]}</Typography>}
            </CardContent>
          </CardBase>
        </Grid>}

      </Grid>
    </Box>
  </Paper>;
}

