import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import FlakyIcon from '@mui/icons-material/Flaky';

import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Paper,
} from '@mui/material';

import {
  Button,
  DatePicker,
  Editor,
  ImagePicker,
  Page,
  Select,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { FriendLink } from '../../../models/FriendLink';
import { Practice } from '../../../models/Practice';
// import { User } from '../../../models/User';

import HelpersLanguage from '../../../actions/helpers/language';

const { DateTime } = require("luxon");

type Props = {}

export const CoachFriendLinkEdit: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();
  const queryClient = useQueryClient();
  
  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [friendCode, setFriendCode] = useState<string>('');
  const [mainObject, setMainObject] = useState<FriendLink>({
    
  });
  const [item, setItem] = useState<File>();
  const [itemUrl, setItemUrl] = useState<string>();
  const [clImageItem, setClImageItem] = useState<File>();
  const [clImageItemUrl, setClImageItemUrl] = useState<string>();

  const [errors, setErrors] = useState<{[z:string]: boolean}>({});

  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ["friend_link", (login?.practice_id || currentPractice?.id), objectId],
    queryFn: () =>
      fetch_one<FriendLink>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'news_item',
          id: objectId,
          fields: [
            'id', 'extra_data'
          ]
        },
        login
      ),
    enabled: !!(login?.practice_id || currentPractice?.id) && parseInt(objectId || '', 10) > 0,
  });
  const {
    data: friendly,
    isLoading: friendlyLoading,
    isError: friendlyError,
    isSuccess: friendlySuccess,
    refetch: friendlyRefetch,
  } = useQuery({
    queryKey: ["friendly", friendCode],
    queryFn: () =>
      fetch_one<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'practice',
          friend_code: friendCode,
          fields: [
            'id', 'name', 'settings', 'get_medium', 'coach_full_list','full_find_teaser', 'locations'
          ],
          sub_fields: {
            locations: ['id', 'name', 'enabled', 'is_hidden']
          }
        },
        login
      ),
    enabled: !!(login?.practice_id || currentPractice?.id) && (friendCode || '').length === 4,
  });







  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      keepOpen?: boolean
    }) => {
      return fetch_one<FriendLink>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setSavedOpen(true);
      queryClient.invalidateQueries({ queryKey: ["coach", "friend_links", (login?.practice_id || currentPractice?.id)] });
      if (!variables.keepOpen) navigate("/friend_links");
      if (!!variables.keepOpen && objectId === 'new') navigate(`/friend_link/${data.id}`);
      if (!!variables.keepOpen && objectId !== 'new') objectRefetch();
    },
  });





  const saveObject = (keep_open: boolean) => {
    mutationSave.mutate({
      formData: {
        object: 'friend_link',
        handler_id: login?.id,
        fields: ['id'],
        ob: mainObject
      },
      keepOpen: keep_open
    });
  };

  let term = t('coach.models.friend_links.table_title');
  let termSingle = t("coach.models.friend_links.singular", "FriendLink");

  let page_title;
  if (typeof((object || {}).id) === 'undefined') {
    page_title = termSingle;
  } else {
    page_title = t("general.titles.edit", "Wijzig") + " " + termSingle;
  }

  useEffect(() => {
    if (params.id !== 'new') objectRefetch();
  }, [params.id]);
  useEffect(() => {
    if (!!objectSuccess) setMainObject(object);
  }, [object]);
  useEffect(() => {
    let err:any = {};
    // if (!mainObject.title) err.title = true;
    // if (!mainObject.content_layout_key) err.content_layout_key = true;
    // if (!mainObject.author) err.author = true;
    // if (!mainObject.teaser) err.teaser = true;
    // if (!!mainObject.enabled && !mainObject.published_at) err.published_at = true;
    // if (!mainObject.coach_id) err.coach_id = true;
    // if (!mainObject.start_time) err.start_time = true;
    // if (!mainObject.end_time) err.end_time = true;
    // if (!!mainObject.start_time && !!mainObject.end_time && DateTime.fromFormat(mainObject.start_time, "dd/LL/y t") > DateTime.fromFormat(mainObject.end_time, "dd/LL/y t")) err.start_time = true;
    // if ((mainObject.max_subscribers || 0) < 1) err.max_subscribers = true;
    
    setErrors(err);
  }, [mainObject]);

  return <Page
    title={page_title}
    titleCrumbs={[
      {
        title: term,
        path: "/friend_links"
      }
    ]}
  >
    <Paper sx={{padding: 2}}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5">{t("friend_links.headings.find_practice")}</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput
            id="friend_code"
            caption={t("coach.models.friend_links.friend_code")}
            starred
            value={friendCode || ''}
            onChange={(e) => {
              setFriendCode(e);
            }}
          />
          {!!friendly?.id && <Button
            label={t("friend_links.cta.pick_practice")}
            id="pick_practice"
            onClick={() => {
              setMainObject({
                ...mainObject,
                initiator_id: login?.id,
                inviter_id: currentPractice?.id,
                invited_id: friendly.id
              });
            }}
          />}
        </Grid>
        <Grid item xs={12} md={8}>
          <Box>
            {!!friendlyLoading && <CircularProgress />}
            {!friendlyLoading && !!friendlySuccess && !!friendly.id && <>
              <Grid item xs={12}>
                <Box sx={{
                  padding: 4,
                  paddingLeft: '232px',
                  position: 'relative',
                  borderRadius: '16px',
                  border: '1px solid transparent',
                  borderColor: theme.palette.primary.main,
                  backgroundColor: 'white'
                }}>
                  <Box sx={{
                    width: '136px',
                    height: "calc(100% - 64px)",
                    position: 'absolute',
                    left: 32,
                    top: 32,
                    backgroundImage: `url(${friendly.get_medium})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat'
                  }} />
                  <Box sx={{
                    height: '100%',
                    position: 'absolute',
                    left: 200,
                    top: 0,
                    width: '1px',
                    borderLeft: '1px solid transparent',
                    borderColor: theme.palette.primary.main,
                  }} />

                  <Typography sx={{
                    fontWeight: 'bold'
                  }}>{friendly.name}</Typography>
                  <Typography>{(friendly.coach_full_list || []).map(coach => <Box sx={{
                    width: 'auto',
                    display: 'inline-block',
                    border: '1px solid transparent',
                    // borderColor: theme.palette.primary.main,
                    borderRadius: '32px',
                    padding: 0,
                    position: 'relative',
                    paddingLeft: '64px',
                    paddingRight: '16px',
                    height: '48px',
                    marginRight: 2,
                    marginTop: 1,
                    marginBottom: 1
                  }}>
                    <Avatar src={coach.get_medium} sx={{
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      height: '48px',
                      width: '48px'
                    }} />
                    <Typography sx={{
                      lineHeight: '48px'
                    }}>{[coach.first_name, coach.last_name].join(" ")}</Typography>
                  </Box>)}</Typography>
                  {!!(friendly.full_find_teaser || {})[HelpersLanguage.mapping_to_id(i18n.resolvedLanguage || 'nl')] && <Typography sx={{
                    paddingLeft: 2,
                    marginTop: 2,
                    borderLeft: '2px solid transparent',
                    borderColor: theme.palette.primary.main,
                    color: 'grey',
                    fontStyle: 'italic'
                  }}>{(friendly.full_find_teaser || {})[HelpersLanguage.mapping_to_id(i18n.resolvedLanguage || 'nl')]}</Typography>}
                </Box>
              </Grid>
            </>}
            {!friendlyLoading && !!friendlySuccess && !friendly.id && <>
              <Typography>{t('friend_links.errors.invalid_code')}</Typography>
            </>}
          </Box>
        </Grid>

        {!!mainObject.invited_id && <>
          <Grid item xs={12}>
            <Typography variant="h5">{t("friend_links.headings.rights")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{paddingLeft: "32px !important"}}>
            <Typography variant="subtitle1">{t("friend_links.headings.nutri_mail_recipient")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{paddingLeft: "48px !important"}}>
            <Switcher
              disabled
              label={t("friend_links.rights.nutri_mail_recipient")}
              value={true}
              onChange={() => {}}
            />
          </Grid>

          <Grid item xs={12} sx={{paddingLeft: "32px !important", marginTop: 4}}>
            <Typography variant="subtitle1">{t("friend_links.headings.agenda")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{paddingLeft: "48px !important"}}>
            <Switcher
              label={t("friend_links.rights.agenda.enabled")}
              value={!!mainObject?.extra_data?.rights?.agenda?.enabled}
              onChange={(e) => {
                setMainObject({
                  ...mainObject,
                  extra_data: {
                    ...(mainObject?.extra_data || {}),
                    rights: {
                      ...(mainObject?.extra_data?.rights || {}),
                      agenda: {
                        ...(mainObject?.extra_data?.rights?.agenda || {}),
                        enabled: !!e
                      }
                    }
                  }
                });
              }}
            />
          </Grid>
          {!!mainObject?.extra_data?.rights?.agenda?.enabled && <Grid item xs={12} sx={{paddingLeft: "48px !important", marginTop: 2}}>
            <Grid container spacing={1}>
              <Grid item xs={9} sx={{
                borderLeft: '1px solid transparent',
                borderBottom: '1px solid transparent',
                borderColor: theme.palette.primary.main
              }}>
                <Typography sx={{
                  fontWeight: 'bold'
                }}>{t("friend_links.rights.agenda.locations.location_name")}</Typography>
              </Grid>
              <Grid item xs={1} sx={{
                borderLeft: '1px solid transparent',
                borderBottom: '1px solid transparent',
                borderColor: theme.palette.primary.main,
                textAlign: 'center'
              }}>
                <DoNotDisturbIcon sx={{color: theme.palette.secondary.main}} />
              </Grid>
              <Grid item xs={1} sx={{
                borderLeft: '1px solid transparent',
                borderBottom: '1px solid transparent',
                borderColor: theme.palette.primary.main,
                textAlign: 'center'
              }}>
                <FlakyIcon sx={{color: theme.palette.warning.main}} />
              </Grid>
              <Grid item xs={1} sx={{
                borderLeft: '1px solid transparent',
                borderRight: '1px solid transparent',
                borderBottom: '1px solid transparent',
                borderColor: theme.palette.primary.main,
                textAlign: 'center'
              }}>
                <CheckCircleIcon sx={{color: theme.palette.primary.main}} />
              </Grid>
              
              {friendly?.locations.map(location => <>
                <Grid item xs={9} sx={{
                  borderLeft: '1px solid transparent',
                  borderTop: '1px solid transparent',
                  borderBottom: '1px solid transparent',
                  borderColor: theme.palette.primary.main
                }}>
                  <Typography sx={{
                    ...(!location.enabled ? {
                      color: 'lightgrey',
                      textDecoration: 'line-through'
                    } : {})
                  }}>{location.name}</Typography>
                </Grid>
                <Grid item xs={1} sx={{
                  borderLeft: '1px solid transparent',
                  borderTop: '1px solid transparent',
                  borderBottom: '1px solid transparent',
                  borderColor: theme.palette.primary.main,
                  textAlign: 'center',
                  backgroundColor: !(mainObject?.extra_data?.rights?.agenda?.locations || {})[location.id || 0] ? 'lightgrey' : 'transparent'
                }}>
                  <DoNotDisturbIcon sx={{
                    color: (!(mainObject?.extra_data?.rights?.agenda?.locations || {})[location.id || 0] ? theme.palette.secondary.main : 'grey'),
                    cursor: 'pointer'
                  }} onClick={() => {
                    setMainObject({
                      ...mainObject,
                      extra_data: {
                        ...(mainObject?.extra_data || {}),
                        rights: {
                          ...(mainObject?.extra_data?.rights || {}),
                          agenda: {
                            ...(mainObject?.extra_data?.rights?.agenda || {}),
                            locations: {
                              ...(mainObject?.extra_data?.rights?.agenda?.locations || {}),
                              [location.id || 0]: 0
                            }
                          }
                        }
                      }
                    });
                  }} />
                </Grid>
                <Grid item xs={1} sx={{
                  borderLeft: '1px solid transparent',
                  borderTop: '1px solid transparent',
                  borderBottom: '1px solid transparent',
                  borderColor: theme.palette.primary.main,
                  textAlign: 'center',
                  backgroundColor: (mainObject?.extra_data?.rights?.agenda?.locations || {})[location.id || 0] === 1 ? 'lightgrey' : 'transparent'
                }}>
                  <FlakyIcon sx={{
                    color: ((mainObject?.extra_data?.rights?.agenda?.locations || {})[location.id || 0] === 1 ? theme.palette.warning.main : 'grey'),
                    cursor: 'pointer'
                  }} onClick={() => {
                    setMainObject({
                      ...mainObject,
                      extra_data: {
                        ...(mainObject?.extra_data || {}),
                        rights: {
                          ...(mainObject?.extra_data?.rights || {}),
                          agenda: {
                            ...(mainObject?.extra_data?.rights?.agenda || {}),
                            locations: {
                              ...(mainObject?.extra_data?.rights?.agenda?.locations || {}),
                              [location.id || 0]: 1
                            }
                          }
                        }
                      }
                    });
                  }} />
                </Grid>
                <Grid item xs={1} sx={{
                  borderLeft: '1px solid transparent',
                  borderRight: '1px solid transparent',
                  borderTop: '1px solid transparent',
                  borderBottom: '1px solid transparent',
                  borderColor: theme.palette.primary.main,
                  textAlign: 'center',
                  backgroundColor: (mainObject?.extra_data?.rights?.agenda?.locations || {})[location.id || 0] === 2 ? 'lightgrey' : 'transparent'
                }}>
                  <CheckCircleIcon sx={{
                    color: ((mainObject?.extra_data?.rights?.agenda?.locations || {})[location.id || 0] === 2 ? theme.palette.primary.main : 'grey'),
                    cursor: 'pointer'
                  }} onClick={() => {
                    setMainObject({
                      ...mainObject,
                      extra_data: {
                        ...(mainObject?.extra_data || {}),
                        rights: {
                          ...(mainObject?.extra_data?.rights || {}),
                          agenda: {
                            ...(mainObject?.extra_data?.rights?.agenda || {}),
                            locations: {
                              ...(mainObject?.extra_data?.rights?.agenda?.locations || {}),
                              [location.id || 0]: 2
                            }
                          }
                        }
                      }
                    });
                  }} />
                </Grid>
              </>)}
            </Grid>
          </Grid>}

          <Grid item xs={12} sx={{paddingLeft: "32px !important", marginTop: 4}}>
            <Typography variant="subtitle1">{t("friend_links.headings.workshops")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{paddingLeft: "48px !important"}}>
            <Switcher
              label={t("friend_links.rights.workshops.enabled")}
              value={!!mainObject?.extra_data?.rights?.workshops?.enabled}
              onChange={(e) => {
                setMainObject({
                  ...mainObject,
                  extra_data: {
                    ...(mainObject?.extra_data || {}),
                    rights: {
                      ...(mainObject?.extra_data?.rights || {}),
                      workshops: {
                        ...(mainObject?.extra_data?.rights?.workshops || {}),
                        enabled: !!e
                      }
                    }
                  }
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sx={{paddingLeft: "32px !important", marginTop: 4}}>
            <Typography variant="subtitle1">{t("friend_links.headings.client_sharing")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{paddingLeft: "48px !important"}}>
            <Grid container spacing={1}>
              <Grid item xs={1} sx={{
                textAlign: 'center'
              }}>
                <DoNotDisturbIcon sx={{
                  color: (!mainObject?.extra_data?.rights?.client_sharing?.enabled ? theme.palette.secondary.main : 'grey'),
                  cursor: 'pointer'
                }} onClick={() => {
                  setMainObject({
                    ...mainObject,
                    extra_data: {
                      ...(mainObject?.extra_data || {}),
                      rights: {
                        ...(mainObject?.extra_data?.rights || {}),
                        client_sharing: {
                          ...(mainObject?.extra_data?.rights?.client_sharing || {}),
                          enabled: false
                        }
                      }
                    }
                  });
                }} />
              </Grid>
              <Grid item xs={11}>
                <Typography sx={{
                  color: (!mainObject?.extra_data?.rights?.client_sharing?.enabled ? theme.palette.secondary.main : 'grey'),
                  cursor: 'pointer'
                }} onClick={() => {
                  setMainObject({
                    ...mainObject,
                    extra_data: {
                      ...(mainObject?.extra_data || {}),
                      rights: {
                        ...(mainObject?.extra_data?.rights || {}),
                        client_sharing: {
                          ...(mainObject?.extra_data?.rights?.client_sharing || {}),
                          enabled: false
                        }
                      }
                    }
                  });
                }}>{t("friend_links.rights.client_sharing_disabled")}</Typography>
              </Grid>
              <Grid item xs={1} sx={{
                textAlign: 'center'
              }}>
                <FlakyIcon sx={{
                  color: (!!mainObject?.extra_data?.rights?.client_sharing?.enabled && mainObject?.extra_data?.rights?.client_sharing?.mode === 'whitelist' ? theme.palette.warning.main : 'grey'),
                  cursor: 'pointer'
                }} onClick={() => {
                  setMainObject({
                    ...mainObject,
                    extra_data: {
                      ...(mainObject?.extra_data || {}),
                      rights: {
                        ...(mainObject?.extra_data?.rights || {}),
                        client_sharing: {
                          ...(mainObject?.extra_data?.rights?.client_sharing || {}),
                          enabled: true,
                          mode: 'whitelist'
                        }
                      }
                    }
                  });
                }} />
              </Grid>
              <Grid item xs={11}>
                <Typography sx={{
                  color: (!!mainObject?.extra_data?.rights?.client_sharing?.enabled && mainObject?.extra_data?.rights?.client_sharing?.mode === 'whitelist' ? theme.palette.warning.main : 'grey'),
                  cursor: 'pointer'
                }} onClick={() => {
                  setMainObject({
                    ...mainObject,
                    extra_data: {
                      ...(mainObject?.extra_data || {}),
                      rights: {
                        ...(mainObject?.extra_data?.rights || {}),
                        client_sharing: {
                          ...(mainObject?.extra_data?.rights?.client_sharing || {}),
                          enabled: true,
                          mode: 'whitelist'
                        }
                      }
                    }
                  });
                }}>{t("friend_links.rights.client_sharing_whitelist")}</Typography>
              </Grid>
              <Grid item xs={1} sx={{
                textAlign: 'center'
              }}>
                <CheckCircleIcon sx={{
                  color: (!!mainObject?.extra_data?.rights?.client_sharing?.enabled && mainObject?.extra_data?.rights?.client_sharing?.mode === 'full' ? theme.palette.primary.main : 'grey'),
                  cursor: 'pointer'
                }} onClick={() => {
                  setMainObject({
                    ...mainObject,
                    extra_data: {
                      ...(mainObject?.extra_data || {}),
                      rights: {
                        ...(mainObject?.extra_data?.rights || {}),
                        client_sharing: {
                          ...(mainObject?.extra_data?.rights?.client_sharing || {}),
                          enabled: true,
                          mode: 'full'
                        }
                      }
                    }
                  });
                }} />
              </Grid>
              <Grid item xs={11}>
                <Typography sx={{
                  color: (!!mainObject?.extra_data?.rights?.client_sharing?.enabled && mainObject?.extra_data?.rights?.client_sharing?.mode === 'full' ? theme.palette.primary.main : 'grey'),
                  cursor: 'pointer'
                }} onClick={() => {
                  setMainObject({
                    ...mainObject,
                    extra_data: {
                      ...(mainObject?.extra_data || {}),
                      rights: {
                        ...(mainObject?.extra_data?.rights || {}),
                        client_sharing: {
                          ...(mainObject?.extra_data?.rights?.client_sharing || {}),
                          enabled: true,
                          mode: 'full'
                        }
                      }
                    }
                  });
                }}>{t("friend_links.rights.client_sharing_full")}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{paddingLeft: "32px !important", marginTop: 4}}>
            <Typography variant="subtitle1">{t("friend_links.headings.appointment")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{paddingLeft: "48px !important"}}>
            <Switcher
              label={t("friend_links.rights.appointment.enabled")}
              value={!!mainObject?.extra_data?.rights?.appointment?.enabled}
              onChange={(e) => {
                setMainObject({
                  ...mainObject,
                  extra_data: {
                    ...(mainObject?.extra_data || {}),
                    rights: {
                      ...(mainObject?.extra_data?.rights || {}),
                      appointment: {
                        ...(mainObject?.extra_data?.rights?.appointment || {}),
                        enabled: !!e
                      }
                    }
                  }
                });
              }}
            />
          </Grid>
        </>}


        







        <Grid item xs={12} sx={{
          alignContent: 'baseline',
          textAlign: 'center',
          position: {
              xs: 'relative',
              md: 'sticky'
            },
          bottom: '-24px',
          backgroundColor: 'white',
          zIndex: 1201
        }}>
          <Divider sx={{marginTop: 1, marginBottom: 0}} />

          {!!mutationSave.isPending && <CircularProgress />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.save")}
            id='save'
            disabled={Object.keys(errors).length > 0}
            contained
            onClick={(v) => {
              saveObject(false);
            }}
            sx={{marginBottom: 1, marginTop: 1}}
          />}
          {/* {!mutationSave.isPending && <Button
            label={t("shared.buttons.save_keep_open")}
            id='save_opened'
            disabled={Object.keys(errors).length > 0}
            contained
            onClick={(v) => {
              saveObject(true);
            }}
            sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
          />} */}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.cancel")}
            id='cancel'
            text
            // contained
            onClick={(v) => {
              navigate("/news_items");
            }}
            sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
          />}
        </Grid>
      </Grid>
    </Paper>
    
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
  </Page>;
}
