import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

import { fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import LeakAddIcon from '@mui/icons-material/LeakAdd';
import LeakRemoveIcon from '@mui/icons-material/LeakRemove';

import {
  Box,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
} from '@mui/material';

import {
  Fab,
  Page,
  Typography
} from '../../../components/v2/styled';

import { Practice } from '../../../models/Practice';

// const { DateTime } = require("luxon");

type Props = {}

export const CoachFriendLinks: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();







  const fields = [
    'id', 'get_friend_code'
  ];

  const {
    data: extendedPracticeTemp,
    isLoading: extendedPracticeLoading,
    isError: extendedPracticeError,
    isSuccess: extendedPracticeSuccess,
    refetch: aextendedPracticeRefetch,
  } = useQuery({
    queryKey: ["friend_link_settings", currentPractice?.id],
    queryFn: () =>
      fetch_one<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'practice',
          fields: fields,
          id: currentPractice?.id
        },
        login
      ),
      // select: (d) => {
      //   return d.custom_result;
      // },
    enabled: !!currentPractice?.id,
  });








  
  return <Page>
    {!!login?.is_super && <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Paper sx={{
            padding: 2
          }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h3">{t("coach.friend_links.title")}</Typography>
                <Typography variant="body1">{t("coach.friend_links.description")}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper sx={{
            padding: 2
          }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h3">{t("coach.friend_links.friend_code_title")}</Typography>
                <Typography variant="body1" sx={{fontSize: 56}}>{extendedPracticeTemp?.get_friend_code}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Fab
        onClick={() => {
          navigate("/friend_link/new");
        }}
      />
    </>}
  </Page>;
}

