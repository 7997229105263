import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { loginAtom } from '../../../lib/auth';

import HelpIcon from '@mui/icons-material/Help';

import {
  Box,
  Button,
  DialogActions,
  Divider,
  Grid,
  Slider,
  Tab,
  Tabs,
  Tooltip,
  useMediaQuery,
} from '@mui/material';

import {
  TextInput,
  TimePickerNew,
  Typography
} from '../../../components/v2/styled';
import {
  FaceUnhappy,
  FaceHappy,
  FaceVeryUnhappy,
  FaceVeryHappy
} from '../../../components/v2/faces';
import { Base } from './Base';

import { DiaryDay } from '../../../models/DiaryDay';
import { MoveMoment as MoveMmnt } from '../../../models/MoveMoment';

const { DateTime } = require("luxon");

type Props = {
  onClick: () => void;
  open: boolean;
  setOpen: (b:boolean) => void;
  newMoveMoment: MoveMmnt;
  setNewMoveMoment: (n:MoveMmnt) => void;
  diaryDay?: DiaryDay
};

export const ClientMoveMomentEdit: FunctionComponent<Props> = ({open, setOpen, onClick, diaryDay, newMoveMoment, setNewMoveMoment}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const [login, setLogin] = useAtom(loginAtom);
  const {t, i18n} = useTranslation(['translations']);

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [currentTab, setCurrentTab] = useState<string>((Object.values(login?.full_move_groups || {}).filter(fff => fff.practices.length > 0).length > 0 ? 'move_diary.groups.short' : 'move_diary.groups.everything'));
  const [errors, setErrors] = useState<{[z:string]: string}>({});
// console.log(login?.full_move_groups);
// console.log(DateTime.now().toFormat("t"));

  const checkFieldValidity = (fieldName:string) => {
    if (currentTab === "move_diary.groups.everything") return true;

    return Object.values(login?.full_move_groups || {}).filter(ff => ff.practices.length > 0 && ff.profile.questions.indexOf(fieldName) > -1).length > 0
  }
  const checkHelperIcon = (fieldName:string) => {
    if (currentTab === "move_diary.groups.everything") return '';

    let practices:string[] = [];
    Object.values(login?.full_move_groups || {}).map((ff, i) => {
      if (ff.profile.questions.indexOf(fieldName) > -1) practices = [...practices, ...(ff.practices.map(qq => qq[1]))];
    });

    let str = `${t("move_diary.groups.asked_by")}<ul>`;
    let pr = practices.map((i) => {
      str += `<li>${i}</li>`
    });
    str += `</ul>`;
    return <Typography html sx={{padding: 1}}>{str}</Typography>;
  }

  return (<>
    <Base
      name="move_moment_edit"
      title={t("client.dashboard.move_diary.new_entry_title", "Nieuwe ingave")}
      fullWidth
      onClose={() => {
        setNewMoveMoment({feeling: -1, intensity: -1});
      }}
      fullScreen={fullScreen}
      maxWidth="lg"
      content={<>

        <Tabs
          value={currentTab}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          onChange={(e, value) => {setCurrentTab(value);}} indicatorColor="primary" textColor="primary" centered={false}>
          <Tab label={t("move_diary.groups.everything")} value={"move_diary.groups.everything"} />
          {Object.values(login?.full_move_groups || {}).filter(fff => fff.practices.length > 0).length > 0 && <Tab label={t("move_diary.groups.short")} value={"move_diary.groups.short"} />}
        </Tabs>

        <Divider />

        <Box>
          <Grid container sx={{
            marginTop: 1
          }} spacing={2}>

            <Grid item sm={6} xs={12} hidden={!checkFieldValidity('time')}>
              <Typography sx={{marginTop: 1, fontSize: '0.7rem'}}>{t("client.models.move_moments.entry_time", "Tijdstip")}</Typography>
              {!!errors.entry_time && <React.Fragment>
                <Typography variant={"caption"} sx={{marginTop: 1, color: theme.palette.secondary.main}}>{t("client.models.move_moments.errors.entry_time", "Dit is een verplicht veld")}</Typography>
              </React.Fragment>}

              <TimePickerNew
                id="entry_time"
                value={newMoveMoment.given_entry || DateTime.now().toFormat("t")}
                setValue={(e) => {
                  setNewMoveMoment({
                    ...newMoveMoment,
                    entry_time: e || undefined,
                    given_entry: e || undefined
                  });
                }}
                greyBg
              />

              {/* <TimePicker
                value={newMoveMoment.given_entry || DateTime.now().toFormat("t")}
                setValue={(e) => {
                  setNewMoveMoment({
                    ...newMoveMoment,
                    entry_time: e || undefined,
                    given_entry: e || undefined
                  });
                }}
                ampm={false}
                openTo="hours"
                label=""
                id="entry_time"
                sx={{
                  field: {
                    marginTop: 0
                  }
                }}
                // cancelLabel="Annuleer"
                greyBg
              /> */}
            </Grid>

            <Grid item sm={6} xs={12} hidden={!checkFieldValidity('activity')}>
              <TextInput
                id="activity"
                helperIcon={checkHelperIcon('activity')}
                placeholder={t("client.models.move_moments.placeholders.activity", "Wandelen, fietsen, lopen, ...")}
                caption={t("client.models.move_moments.activity", "Activiteit tijdens het beweegmoment")}
                autoComplete="activity"
                autoFocus={false}
                backend
                greyBg
                value={newMoveMoment.activity || ''}
                onChange={(e) => {
                  setNewMoveMoment({
                    ...newMoveMoment,
                    activity: e || undefined
                  });
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12} hidden={!checkFieldValidity('duration')}>
              <TextInput
                id="duration"
                helperIcon={checkHelperIcon('duration')}
                placeholder={t("client.models.move_moments.placeholders.duration", "")}
                caption={t("client.models.move_moments.duration", "Duurtijd (in minuten)")}
                autoComplete="duration"
                type="number"
                autoFocus={false}
                backend
                greyBg
                value={newMoveMoment.duration || ''}
                onChange={(e) => {
                  setNewMoveMoment({
                    ...newMoveMoment,
                    duration: e || undefined
                  });
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12} hidden={!checkFieldValidity('amount_steps')}>
              <TextInput
                id="amount_steps"
                helperIcon={checkHelperIcon('amount_steps')}
                placeholder={t("client.models.move_moments.placeholders.amount_steps", "")}
                caption={t("client.models.move_moments.amount_steps", "Aantal stappen (indien van toepassing)")}
                autoComplete="amount_steps"
                type="number"
                autoFocus={false}
                backend
                greyBg
                value={newMoveMoment.amount_steps || ''}
                onChange={(e) => {
                  setNewMoveMoment({
                    ...newMoveMoment,
                    amount_steps: e || undefined
                  });
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12} hidden={!checkFieldValidity('amount_km')}>
              <TextInput
                id="amount_km"
                helperIcon={checkHelperIcon('amount_km')}
                placeholder={t("client.models.move_moments.placeholders.amount_km", "")}
                caption={t("client.models.move_moments.amount_km", "Aantal km (indien van toepassing)")}
                autoComplete="amount_km"
                type="number"
                autoFocus={false}
                backend
                greyBg
                value={newMoveMoment.amount_km || ''}
                onChange={(e) => {
                  setNewMoveMoment({
                    ...newMoveMoment,
                    amount_km: e || undefined
                  });
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12} hidden={!checkFieldValidity('intensity')}>
              <Typography variant={"caption"} sx={{marginTop: 1}}>{t("client.models.move_moments.intensity", "Intensiteit")}</Typography>
              {!!checkHelperIcon('hunger') && <Tooltip title={checkHelperIcon('intensity')}><HelpIcon sx={{
                float: 'right',
                color: theme.palette.primary.main
              }} /></Tooltip>}
              <br />
              <Slider
                value={newMoveMoment.intensity || -1}
                onChange={(ev, val, e) => {
                  setNewMoveMoment({
                    ...newMoveMoment,
                    intensity: typeof(val) === "number" ? val : val[0] || undefined
                  });
                }}
                step={1}
                max={10}
                sx={{marginLeft: '10%', marginTop: 0, width: '80%'}}
                marks={[
                  {
                    value: 0,
                    label: t("client.models.move_moments.intensity_none", "Geen"),
                  },
                  {
                    value: 10,
                    label: t("client.models.move_moments.intensity_much", "Veel"),
                  },
                ]}
                valueLabelDisplay="on"
              />
            </Grid>

            <Grid item sm={6} xs={12} hidden={!checkFieldValidity('feeling')}>
              <Typography variant={"caption"} sx={{marginTop: 1}}>{t("client.models.move_moments.feeling", "Gevoel na het beweegmoment")}</Typography>
              {!!checkHelperIcon('hunger') && <Tooltip title={checkHelperIcon('feeling')}><HelpIcon sx={{
                float: 'right',
                color: theme.palette.primary.main
              }} /></Tooltip>}
              <br />

              <FaceVeryUnhappy
                activeValue={parseInt(newMoveMoment.feeling?.toString() || '-1', 10)}
                onClick={(e) => {
                  setNewMoveMoment({
                    ...newMoveMoment,
                    feeling: e
                  });
                }}
              />
              <FaceUnhappy
                activeValue={newMoveMoment.feeling || -1}
                onClick={(e) => {
                  setNewMoveMoment({
                    ...newMoveMoment,
                    feeling: e || undefined
                  });
                }}
              />
              <FaceHappy
                activeValue={newMoveMoment.feeling || -1}
                onClick={(e) => {
                  setNewMoveMoment({
                    ...newMoveMoment,
                    feeling: e || undefined
                  });
                }}
              />
              <FaceVeryHappy
                activeValue={newMoveMoment.feeling || -1}
                onClick={(e) => {
                  setNewMoveMoment({
                    ...newMoveMoment,
                    feeling: e || undefined
                  });
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12} hidden={!checkFieldValidity('location')}>
              <TextInput
                id="location"
                helperIcon={checkHelperIcon('location')}
                placeholder={t("client.models.move_moments.placeholders.location", "Thuis, in het park, ...")}
                caption={t("client.models.move_moments.location", "Locatie")}
                autoComplete="location"
                autoFocus={false}
                backend
                greyBg
                value={newMoveMoment.location || ''}
                onChange={(e) => {
                  setNewMoveMoment({
                    ...newMoveMoment,
                    location: e || undefined
                  });
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12} hidden={!checkFieldValidity('participants')}>
              <TextInput
                id="participants"
                helperIcon={checkHelperIcon('participants')}
                placeholder={t("client.models.move_moments.placeholders.participants", "Alleen, gezin, ...")}
                caption={t("client.models.move_moments.participants", "Wie nam deel aan het beweegmoment")}
                autoComplete="participants"
                autoFocus={false}
                backend
                greyBg
                value={newMoveMoment.participants || ''}
                onChange={(e) => {
                  setNewMoveMoment({
                    ...newMoveMoment,
                    participants: e || undefined
                  });
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12} hidden={!checkFieldValidity('complaints')}>
              <TextInput
                id="complaints"
                helperIcon={checkHelperIcon('complaints')}
                placeholder={t("client.models.move_moments.placeholders.complaints", "Krampen, geen adem, ...")}
                caption={t("client.models.move_moments.complaints", "Klachten omtrent het beweegmoment")}
                autoComplete="complaints"
                autoFocus={false}
                backend
                greyBg
                value={newMoveMoment.complaints || ''}
                onChange={(e) => {
                  setNewMoveMoment({
                    ...newMoveMoment,
                    complaints: e || undefined
                  });
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12} hidden={!checkFieldValidity('comment_food')}>
              <TextInput
                id="comment_food"
                helperIcon={checkHelperIcon('comment_food')}
                placeholder={t("client.models.move_moments.placeholders.comment_food", "")}
                caption={t("client.models.move_moments.comment_food", "Opmerkingen over maaltijden/snacks gerelateerd aan het beweegmoment")}
                autoComplete="comment_food"
                autoFocus={false}
                multiline
                backend
                greyBg
                value={newMoveMoment.comment_food || ''}
                onChange={(e) => {
                  setNewMoveMoment({
                    ...newMoveMoment,
                    comment_food: e || undefined
                  });
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12} hidden={!checkFieldValidity('comment')}>
              <TextInput
                id="comment"
                helperIcon={checkHelperIcon('comment')}
                placeholder={t("client.models.move_moments.placeholders.comment", "")}
                caption={t("client.models.move_moments.comment", "Extra opmerkingen over het beweegmoment")}
                autoComplete="comment"
                autoFocus={false}
                multiline
                backend
                greyBg
                value={newMoveMoment.comment || ''}
                onChange={(e) => {
                  setNewMoveMoment({
                    ...newMoveMoment,
                    comment: e || undefined
                  });
                }}
              />
            </Grid>

          </Grid>
        </Box>
      </>}
      actions={<DialogActions>
        <Button
          onClick={(e) => {
            setNewMoveMoment({feeling: -1, intensity: -1});
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.close")}</Button>
        <Button
          onClick={onClick}
          color="primary"
        >{t("coach.general.actions.save")}</Button>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
  </>);
}
